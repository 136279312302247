import { IFormData } from '@root-gipro/modules/commonProjects/models/interfaces/cost-works.state'
import {
	IValuationOfferForecast,
	IVisitsLog,
} from '@root-gipro/modules/commonProjects/models/interfaces/reports.models'
import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import store from '@root-gipro/store'
import { setPublicUncTechnicalSpecifications } from '@root-gipro/store/actions/public'
import { fetchData, fetchHeaders } from '@root-gipro/store/api'
import {
	ICommonProject,
	ICommonProjectsRequest,
	IRequestProject,
	ISelectCommonProject,
	IUncInfo,
	IUncsRequest,
	IUncTechnicalSpecificationsSelectCommonProject,
} from '@root-gipro/store/interfaces'
import { handlerErrors } from '@root-gipro/utils/helpers/handlerErrors'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
	addToUserProjectApi,
	commonProjectsApi,
	downloadCommonProjectApi,
	getSelectCommonProjectApi,
	getUncsCommonProjectApi,
	getUncTechnicalSpecificationsCommonProjectApi,
	getUserInfoWorker,
	requestRgStatusesApi,
} from '../../../store/api'
import { CommonProjectsType } from '../models/interfaces/common-projects.actions'
import {
	loadingCommonProjects,
	selectCommonProject,
	setAllowMoreProjects,
	setCommonProjects,
	setCommonProjectsCount,
	setCommonProjectsPaginationCount,
	setCommonProjectsSum,
	setIdAddedUserProject,
	setReporting,
	setUncInfo,
	setUncsCommonProject,
	setUncTechnicalSpecificationsCommonProject,
	setUserInfoReport,
} from './actions'

function* sortCommonProjects({
	order,
	sortName,
}: {
	type: typeof CommonProjectsType.SORT_COMMON_PROJECTS;
	sortName: string;
	order?: boolean;
}) {
	try {
		yield put(loadingCommonProjects(true))
		yield put(setCommonProjects([]))
		let params = {
			order: (order ? '' : '-') + sortName,
			limit: 5,
			with: [
				'resultsCnt',
				'valuationOfferForecastSum',
				'valuationPlanForecastSum',
				'projects',
				'uncInfo',
				'uncTechnicalSpecifications',
			],
		}

		const { commonProjects } = store.getState().filter
		const object = excludeData(commonProjects.showFilterState)

		const intermediateValue = {
			...object,
		}

		if (commonProjects.showFilterState || !!Object.keys(intermediateValue).length) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		const res: ICommonProjectsRequest = yield call(commonProjectsApi, params)

		if (res && res.status === 'success') {
			const projects: ICommonProject[] = res.projects
			const count = Number(res.resultsCnt)
			const valuationOfferForecastSum: number = Number(res.valuationOfferForecastSum) / 10 ** 6
			const valuationPlanForecastSum: number = Number(res.valuationPlanForecastSum) / 10 ** 6
			const uncInfo: IUncInfo = res.uncInfo
			const uncTechnicalSpecifications = res.uncTechnicalSpecifications
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			const newVoltageValues = Object.values(uncInfo.voltageValues)
			uncInfo.voltageValues = newVoltageValues
			yield put(setCommonProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
			yield put(setCommonProjects(projects))
			yield put(setCommonProjectsCount(count))
			yield put(setUncInfo(uncInfo))
			yield put(setPublicUncTechnicalSpecifications(uncTechnicalSpecifications))
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(loadingCommonProjects(false))
	}
}

function* reportRequest({
	params,
}: {
	type: typeof CommonProjectsType.REPORT_REQUEST;
	params: IValuationOfferForecast | IVisitsLog;
}) {
	const { showFilterState } = store.getState().filter.commonProjects
	const { roleId } = store.getState().auth.user
	let fetchUrl: string
	let nameFile: string
	try {
		if ((params as IValuationOfferForecast).sortName && (params as IValuationOfferForecast).name) {
			const order =
				((params as IValuationOfferForecast).order ? '' : '-') + (params as IValuationOfferForecast).sortName
			let link = ''
			Object.keys(showFilterState).forEach(el => (link = link + `&${el}=${showFilterState[el]}`))

			fetchUrl = `https://${process.env.REACT_APP_ENV}/ptk/v2/project-general-search/consLoadExcel?order=${order}${link}`
			nameFile = 'GIPRO Сводный отчет'
		} else {
			const email = (params as IVisitsLog).email
			const to = (params as IVisitsLog).to
			const from = (params as IVisitsLog).from
			// 50 - роль Техподдержки
			fetchUrl =
				roleId === 50
					? `https://${process.env.REACT_APP_ENV}/ptk/v2/visitsLog/toXLSX?email=${email}&from=${from}&to=${to}`
					: ''
			nameFile = 'document'
		}

		yield put(setReporting(true))

		yield fetch(fetchUrl, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => {
				if (res.status >= 200 && res.status < 300) {
					return res.blob()
				}
				return res.text().then(text => {
					throw JSON.parse(text)
				})
			})
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `${nameFile}.xlsx`
				link.click()
				link.remove()
			})
		yield put(setReporting(false))
	} catch (error) {
		handlerErrors(error)
	}
}

function* userInfoAction({ payload }: { type: typeof CommonProjectsType.USERINFO_REQUEST; payload: any }) {
	const email = payload

	try {
		const res = yield call(getUserInfoWorker, email)
		if (res.status === 'success') {
			const data: any = res.userInfo
			// console.log(data)
			yield put(setUserInfoReport(data))
		}
	} catch (error) {
		handlerErrors(error)
	}
}

function* appendCommonProjects({
	projectsCount,
}: {
	type: typeof CommonProjectsType.APPEND_COMMON_PROJECTS;
	projectsCount: number;
}) {
	try {
		yield put(loadingCommonProjects(true))
		const { sort, allowMoreProjects } = store.getState().commonProjects

		if (!allowMoreProjects) return

		let projects: ICommonProject[] = store.getState().commonProjects.projects.slice()
		let params = {
			order: (sort.order ? '' : '-') + sort.sortName,
			limit: 5,
			offset: projectsCount * 5,
		}

		const { commonProjects } = store.getState().filter
		const object = excludeData(commonProjects.showFilterState)

		const intermediateValue = {
			...object,
		}

		if (commonProjects.showFilterState) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		const res: Omit<
			ICommonProjectsRequest,
			'uncInfo,valuationOfferForecastSum, resultsCnt,valuationPlanForecastSum'
		> = yield call(commonProjectsApi, params)

		if (res && res.status === 'success') {
			projects = [...projects, ...res.projects]

			yield put(setCommonProjects(projects))
			yield put(setCommonProjectsPaginationCount(projectsCount))
			if (!(res.projects.length || res.projects.length > 5)) {
				yield put(setAllowMoreProjects(false))
				yield put(setCommonProjectsPaginationCount(0))
			}
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(loadingCommonProjects(false))
	}
}

function* getUncsCommonProject({
	id,
}: {
	type: typeof CommonProjectsType.GET_UNCS_COMMON_PROJECT;
	id: string | number;
}) {
	try {
		const params = {
			with: ['uncCell', 'uncMain', 'uncParameter'],
		}
		const res: IUncsRequest = yield call(getUncsCommonProjectApi, params, id)

		if (res && res.status === 'success') {
			yield put(setUncsCommonProject(res.uncs))
		}
	} catch (error) {
		console.log(error)
	}
}

function* getUncTechnicalSpecificationsCommonProject({
	id,
}: {
	type: typeof CommonProjectsType.GET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT;
	id: string | number;
}) {
	try {
		if (!id) return
		const res: IUncTechnicalSpecificationsSelectCommonProject = yield call(
			getUncTechnicalSpecificationsCommonProjectApi,
			id
		)

		if (res && res.status === 'success') {
			yield put(setUncTechnicalSpecificationsCommonProject(res.uncTechnicalSpecifications))
		}
	} catch (error) {
		console.log(error)
	}
}

function* getSelectCommonProject({
	id,
}: {
	type: typeof CommonProjectsType.SELECT_COMMON_PROJECT;
	id: string | number;
}) {
	try {
		const res: ISelectCommonProject = yield call(getSelectCommonProjectApi, id)
		if (res && res.status === 'success') {
			yield put(selectCommonProject(res.project))
		}
	} catch (error) {
		console.log(error)
	}
}

function* downloadCommonProject({
	id,
	code,
}: {
	type: typeof CommonProjectsType.DOWNLOAD_COMMON_PROJECT;
	id: string | number;
	code: string;
}) {
	try {
		yield call(downloadCommonProjectApi, id, code, 'f20')
	} catch (error) {
		console.log(error)
	}
}
function* downloadUncTechnicalSpecifications({
	id,
	code,
}: {
	type: typeof CommonProjectsType.DOWNLOAD_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT;
	id: string | number;
	code: string;
}) {
	try {
		yield call(downloadCommonProjectApi, id, code, 'uncTechnicalSpecificationsExcel')
	} catch (error) {
		console.log(error)
	}
}

function* addToUserProject({
	projectId,
}: {
	type: typeof CommonProjectsType.ADD_TO_USER_PROJECT;
	projectId: string | number;
}) {
	try {
		const project: IRequestProject = yield call(addToUserProjectApi, projectId)

		if (project.status === 'success') {
			const { code } = project.userProject
			const id = project.userProject.id as string
			yield put(setIdAddedUserProject(id))

			yield put(showNotify({ type: 'success', message: `Создан новый проект ${code}` }))
		}
	} catch (error) {
		console.log(error)
	}
}

function* requestRgStatuses({
	projectId,
}: {
	type: typeof CommonProjectsType.REQUEST_RG_STATUSES;
	projectId: string | number;
}) {
	try {
		const res: ISelectCommonProject = yield call(requestRgStatusesApi, projectId)

		if (res.status === 'success') yield put(selectCommonProject(res.project))
	} catch (error) {
		console.log(error)
	}
}

function* subscribeRequest(action: { type: CommonProjectsType.SUBSCRIBE_REQUEST; data: IFormData }) {
	try {
		const data = action.data
		const res: unknown = yield call(fetchData, `/user-projects/`, (res: unknown) => res, 'POST', data) // res исправить на res.res
	} catch (error) {
		console.log(error)
	}
}

export default function* watchCommonProjects() {
	// yield takeEvery(CommonProjectsType.GET_COMMON_PROJECTS, getCommonProjects)
	yield takeEvery(
		CommonProjectsType.GET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT,
		getUncTechnicalSpecificationsCommonProject
	)
	yield takeEvery(CommonProjectsType.APPEND_COMMON_PROJECTS, appendCommonProjects)
	yield takeEvery(CommonProjectsType.SORT_COMMON_PROJECTS, sortCommonProjects)
	yield takeEvery(CommonProjectsType.REPORT_REQUEST, reportRequest)
	yield takeEvery(CommonProjectsType.USERINFO_REQUEST, userInfoAction)
	yield takeEvery(CommonProjectsType.GET_UNCS_COMMON_PROJECT, getUncsCommonProject)
	yield takeEvery(CommonProjectsType.SELECT_COMMON_PROJECT, getSelectCommonProject)
	yield takeEvery(CommonProjectsType.DOWNLOAD_COMMON_PROJECT, downloadCommonProject)
	yield takeEvery(CommonProjectsType.ADD_TO_USER_PROJECT, addToUserProject)
	yield takeEvery(CommonProjectsType.REQUEST_RG_STATUSES, requestRgStatuses)
	yield takeEvery(
		CommonProjectsType.DOWNLOAD_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT,
		downloadUncTechnicalSpecifications
	)
	yield takeEvery(CommonProjectsType.SUBSCRIBE_REQUEST, subscribeRequest)
}
