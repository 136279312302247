import {
	createStyles,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import React, { FC, useEffect, useState } from 'react'
import AdminPanelTableUserRow from './AdminPanelTableUserRow'

interface IAdminPanelTable {
	users: IUser[]
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiTableCell-root': {
				padding: '6px 12px',
			},
		},
	})
)

function sortUsers(x: IUser, y: IUser) {
	if (x.lastname.toLowerCase() < y.lastname.toLowerCase()) {
		return -1
	}
	if (x.lastname.toLowerCase() > y.lastname.toLowerCase()) {
		return 1
	}
	return 0
}

const AdminPanelTable: FC<IAdminPanelTable> = ({ users }) => {
	const classes = useStyles()
	const [sortedUsers, setSortedUsers] = useState<IUser[]>(users)

	useEffect(() => {
		setSortedUsers((prev: IUser[]) => [...prev].sort(sortUsers))
	}, [users])

	return (
		<TableContainer component={Paper}>
			<div style={{ width: '100%', overflowX: 'auto' }}>
				<Table classes={classes} style={{ minWidth: 1800 }} size='small' stickyHeader aria-label='sticky table dense'>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: 100 }} align='center'>
								Действия
							</TableCell>
							<TableCell style={{ width: 70 }}>№</TableCell>
							<TableCell>Фамилия</TableCell>
							<TableCell>Имя</TableCell>
							<TableCell style={{ width: 215 }}>Дата лицензии</TableCell>
							<TableCell style={{ minWidth: 185 }}>Роль</TableCell>
							<TableCell>Компания</TableCell>
							<TableCell>Филиал</TableCell>

							<TableCell>Почта</TableCell>
							<TableCell style={{ minWidth: 170 }}>Телефон</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedUsers.length
							? sortedUsers.map((user, index) => <AdminPanelTableUserRow user={user} index={index} key={user.id} />)
							: null}
					</TableBody>
				</Table>
			</div>
		</TableContainer>
	)
}

export default AdminPanelTable
