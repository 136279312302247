import { fetchAuthHeaders } from '@root-gipro/store/api'

export const ROLE_OWNER = 5
export const ROLE_DEVELOPER = 3

export async function getUserRole(id: number) {
	try {
		const getUserRoleData = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}/roles`, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
				},
			})
			const data = await req.json()

			const roleId = await data.userRoles.filter((userRole: any) => userRole?.serviceId === 'gipro')[0].id
			return roleId
		}
		const roleId = await getUserRoleData()
		return roleId
	} catch (error) {
		console.log(error)
	}
}

export function getUserAuthRole(id: number) {
	try {
		const getUserRoleData = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}/roles`, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
				},
			})
			const data = await req.json()

			const roleId = await data.userRoles.filter((userRole: any) => userRole?.serviceId === 'auth')[0].id
			return roleId
		}
		const roleId = getUserRoleData()
		return roleId
	} catch (error) {
		console.log(error)
	}
}

export async function getUsersRoles() {
	try {
		const usersRoles = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/user-roles`, {
			method: 'GET',
			headers: fetchAuthHeaders(),
		})

		const data = await usersRoles.json()

		if (data.status === 'success') {
			return data.userRoles.filter((userRole: any) => userRole?.serviceId === 'gipro')
		} else {
			throw Error('Не удалось получить список id')
		}
	} catch (error) {
		console.log(error)
	}
}

export async function getUserAccess(id: number) {
	try {
		const getUserAccessData = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}/access`, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
				},
			})
			const data = await req.json()

			return data.userAccess
		}
		const userAccess = await getUserAccessData()

		return userAccess
	} catch (error) {
		console.log(error)
	}
}
