// todo remake to make the component reusable. take out to shared
import React from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { ListItemText, ListItem, List } from '@material-ui/core/'
import { OptionsProps } from '@root-gipro/modules/commonProjects/components/SelectProjects/DropDown/ParamsOptions'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setIdAddedUserProject } from '@root-gipro/modules/commonProjects/store/actions'

const RatesOptions: React.FC<OptionsProps> = ({
	anchorEl,
	setAnchorEl,
	policesGipro,
	policesRGCO,
	rgStatusId,
	copyHrefToClipboard,
	downloadCommonProject,
	addToUserProject,
	getRgStatusId,
	rgStatuses,
	project,
	downloadUncTechnicalSpecifications,
	idAddedUserProject,
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const handleToUserProject = () => {
		if (project) {
			project && addToUserProject(project.id)
			idAddedUserProject && history.push(`/user-projects/project/${idAddedUserProject}/parameters`)
			dispatch(setIdAddedUserProject(null))
		}
	}

	return (
		project && (
			<DropDown
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				style={{ borderRadius: 4 }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<div className='drop-down drop-down-options'>
					<List className='drop-down__list' component='nav' aria-label='main mailbox folders'>
						<ListItem button className='drop-down-item' onClick={handleToUserProject}>
							<span className='copy-icon icon-size' />
							<ListItemText primary='Скопировать в расчеты' />
						</ListItem>
						{policesRGCO && (
							<ListItem
								button
								className={`drop-down-item ${rgStatusId === '4' ? 'link-disabled' : ''}`}
								onClick={() => {
									if (rgStatusId && rgStatusId !== '4') {
										return window.open(`https://rg.unc-esk.ru/ispoldocums?find=${project.code}`)
									}
									if (!rgStatusId) {
										return getRgStatusId()
									}
								}}
							>
								<span className='query-icon icon-size' />
								<ListItemText primary={rgStatusId ? rgStatuses[parseInt(rgStatusId) - 1].title : `Запросить ИД`} />
							</ListItem>
						)}
						{downloadUncTechnicalSpecifications && (
							<ListItem
								button
								className='drop-down-item'
								onClick={() => downloadUncTechnicalSpecifications(project.id, project.code)}
							>
								<span className='download-icon icon-size' /> <ListItemText primary='Выгрузить' />
							</ListItem>
						)}
					</List>
				</div>
			</DropDown>
		)
	)
}
export default RatesOptions
