import { Grid } from '@material-ui/core'
import { roleIdByAlias } from '@root-gipro/modules/admin/models/roles'
import style from '@root-gipro/modules/header/styles/HeaderNav.module.scss'
import '@root-gipro/modules/header/styles/HeaderNavSubmenu.scss'
import { Routes } from '@root-gipro/router'
import React, { memo, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const HeaderNav: React.FC = memo(() => {
	const [adminMenuIsVisible, setAdminMenuIsVisible] = useState(false)
	const [adminSubMenuClass, setAdminSubMenuClass] = useState('nav-submenu')

	const [showAdminPanel, setShowAdminPanel] = useState(false)

	useEffect(() => {
		if (adminMenuIsVisible) {
			setAdminSubMenuClass('nav-submenu nav-submenu--active')
		} else {
			setAdminSubMenuClass('nav-submenu')
		}
	}, [adminMenuIsVisible])

	const roleId = Number(window.localStorage.getItem('roleId'))
	const isAdmin = roleId <= 10

	useEffect(() => {
		if (roleId !== null) {
			setShowAdminPanel(Number(roleId) === roleIdByAlias('ROLE_DEVELOPER'))
		}
	}, [roleId])
	return (
		<Grid>
			<NavLink to={Routes.COMMON_PROJECTS} activeClassName={style.active} className={style.navLink}>
				Проекты
			</NavLink>
			<NavLink to={Routes.USER_PROJECTS} activeClassName={style.active} className={style.navLink}>
				Расчеты
			</NavLink>
			<NavLink to={Routes.USER_ARCHIVE} activeClassName={style.active} className={style.navLink}>
				Архив
			</NavLink>
			{isAdmin ? (
				<NavLink to={Routes.ADMIN} activeClassName={style.active} className={style.navLink}>
					Администрирование
				</NavLink>
			) : null}
		</Grid>
	)
})

export default HeaderNav
