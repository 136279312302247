import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import { IFilial } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useStylesFormControl = makeStyles({
	root: {
		'& .MuiSelect-icon': {
			padding: 0,
			top: 'calc(50% - 12px)',
			color: 'rgba(0, 0, 0, 0.54)',
			right: 4,
			position: 'absolute',
			pointerEvents: 'none',
		},
	},
})

interface IAdminPanelCreateUserFilial {
	name: string
	label: string
	defaultValue?: string
	getValue: (name: string, value: string) => void
	type: string
	selectedCompany: number
}

const AdminPanelCreateUserFilial: FC<IAdminPanelCreateUserFilial> = ({
	name,
	label,
	getValue,
	type,
	defaultValue,
	selectedCompany,
}) => {
	const classesFromControl = useStylesFormControl()
	const [value, setValue] = React.useState(defaultValue ? defaultValue : '')

	const { filial } = useSelector((state: IState) => state.public)

	const [filials, setFilials] = useState<IFilial[]>(filial.filter(el => el.id === selectedCompany))

	useEffect(() => {
		const companyFilials = filial.filter(f => f.companyId === selectedCompany)
		setFilials(companyFilials)
	}, [selectedCompany])

	const handleChange = (event: any) => {
		getValue(name, event.target.value)
		setValue(event.target.value)
	}

	return (
		<FormControl fullWidth classes={classesFromControl}>
			<label style={{ marginBottom: 8, display: 'block' }}>{label}</label>
			<Select value={value} onChange={handleChange} fullWidth variant='outlined'>
				<MenuItem value={999}>Все филиалы</MenuItem>
				{type === 'filial'
					? filials.map(filial => (
							<MenuItem value={filial.id} key={filial.id}>
								{filial.name}
							</MenuItem>
					  ))
					: null}
			</Select>
		</FormControl>
	)
}

export default AdminPanelCreateUserFilial
