import React, { ReactText, useEffect, useState } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { IRegion } from '@root-gipro/store/interfaces'
import { TbodyArrType, tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'

interface SitePreparationWindow {
	anchorEl: (EventTarget & HTMLElement) | null;
	setAnchorEl(event: HTMLElement): void;
	handler(): void;
	regionList: IRegion[];
	checkedTable: string;
	setCheckedTable(id: string): void;
	activeTotal: number;
	checkedRegionId: ReactText | undefined;
	setCheckedRegion(id: string): void;
	tableNums: string[];
}

const SitePreparationWindow: React.FC<SitePreparationWindow> = ({
	checkedTable,
	handler,
	setCheckedTable,
	anchorEl,
	setAnchorEl,
	activeTotal,
	regionList,
	checkedRegionId,
	setCheckedRegion,
	tableNums,
}) => {
	const buttons = {
		PrimaryBtn: {
			handler: handler,
			value: 'Готово',
			disabled: !activeTotal
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl(null!)
			},
			value: 'Отмена',
		},
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Добавить Б1',
			action: undefined,
		},
	]

	const [tbodyArrFiltered, setTbodyArrFiltered] = useState<TbodyArrType[]>([])

	useEffect(() => {
		let filtered
		filtered = tbodyArr.filter(item => item.addUncShowing).filter(item => item.tableNum !== 8)
		setTbodyArrFiltered(filtered)
	}, [activeTotal])
	
	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
			<div className='activeTotal'>
				<label>
					<span className='activeTotal__label'>Суммарная площадь:</span>
					<input
						className='activeTotal__input'
						type='text'
						disabled
						readOnly
						value={
							(activeTotal || activeTotal === 0)! ? prettyCost(activeTotal! / 1000) : 'Не выбрано ни одной расценки С1'
						}
					/>
				</label>

				{regionList && (
					<>
						<div className='activeTotal__label'>Выберите регион:</div>
						<div className='activeTotal__tables'>
							{regionList.map((elem: IRegion, index) => (
								<div className='table_label' key={elem.id}>
									<input
										type='radio'
										value={elem.id}
										checked={checkedRegionId ? checkedRegionId === elem.id : index == 0}
										readOnly
									/>
									<label onClick={() => setCheckedRegion(elem.id)} className='popup_label'>
										{elem.name}
									</label>
								</div>
							))}
						</div>
					</>
				)}

				<div className='activeTotal__label'>Поместить в таблицу:</div>
				<div className='activeTotal__tables'>
					{tbodyArrFiltered.map((tbody: TbodyArrType) => (
						<div className='table_label' key={tbody.tableNum}>
							<input
								type='radio'
								value={tbody.tableNum}
								checked={checkedTable === String(tbody.tableNum)}
								onChange={() => {
									setCheckedTable(String(tbody.tableNum))
								}}
								name='table_num'
								id={String(tbody.tableNum)}
							/>
							<label htmlFor={String(tbody.tableNum)}>
								{(tbody.tableNum === 8 ? 6 : tbody.tableNum) + '. ' + tbody.titleShort}
							</label>
						</div>
					))}
				</div>
			</div>
		</Modal>
	)
}
export default SitePreparationWindow
