import { TextField } from '@material-ui/core'
import React, { FC } from 'react'

interface IAdminPanelCreateUserInput {
	name: string;
	label: string;
	getValue: (name: string, value: string) => void;
	type: string;
}

const AdminPanelCreateUserInput: FC<IAdminPanelCreateUserInput> = ({ name, label, getValue, type }) => {
	const [value, setValue] = React.useState('')

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		getValue(name, event.target.value)
		setValue(event.target.value)
	}
	return (
		<div>
			<label style={{ marginBottom: 8, display: 'block' }}>{label}</label>
			<TextField
				type={type}
				id={name}
				value={value}
				placeholder={label}
				variant='outlined'
				onChange={handleChange}
			/>
		</div>
	)
}

export default AdminPanelCreateUserInput
