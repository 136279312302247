import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Tbody } from '@root-gipro/modules/userProjects/components/Project/UncTable/Tbody'
import { gridTemplateColumns, theadData } from '@root-gipro/modules/userProjects/models/consts/unc-thead.const'
import { IUnc } from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import { Thead } from '@root-gipro/shared/Table/components/Thead'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import {
	sortUncCellCode,
	sortUncCost,
	sortUncCount,
	sortUncParameters,
	sortUncRegionFactor,
	sortUncTechnicalSolutions,
	sortUncTotalCost,
	sortUncUnit,
	sortUncVoltage,
} from '@root-gipro/utils/helpers/handleSorting'

import {
	filteredTotalUncCost,
	filteredUncCellCode,
	filteredUncCost,
	filteredUncCount,
	filteredUncParameters,
	filteredUncRegionFactor,
	filteredUncTechnicalSolutions,
	filteredUncUnit,
	filteredUncVoltage,
} from '@root-gipro/utils/helpers/handleFiltered'
import TableTabs from './TableTabs'

import { ModalContext } from '@root-gipro/context/modal-context'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import Loader from '@root-gipro/shared/Loader/Loader'
import { LinearProgress } from '@material-ui/core'

interface TableContainerProps {
	uncs: IUnc[];
	sitePreparation: boolean;
}

type filterFieldsProp = { [key: string]: any }

const TableContainer: FC<TableContainerProps> = ({ uncs, sitePreparation }) => {
	const { uncCheckedTable, showLoader } = useSelector((state: IState) => state.userProjects)
	const [uncsFiltered, setUncsFiltered] = useState<IUnc[] | []>([])
	const [filterFields, setFilterFields] = useState<filterFieldsProp>({})

	const theadParams: { [key: string]: any } = useMemo(() => {
		return {
			gridTemplateColumns,
			theadData,
		}
	}, [])

	const updateHandleSorting = (sortField: string, sortOrder: string) => {
		let sorted: [] | IUnc[] = []
		if (sortField === 'Расценка') {
			sorted = sortUncCellCode(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Техническое решение') {
			sorted = sortUncTechnicalSolutions(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Кол-во') {
			sorted = sortUncCount(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Ед.изм.') {
			sorted = sortUncUnit(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Параметры') {
			sorted = sortUncParameters(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Напр., кВ') {
			sorted = sortUncVoltage(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Укр. норматив цены') {
			sorted = sortUncCost(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Региональный коэф.') {
			sorted = sortUncRegionFactor(sortField, sortOrder, uncsFiltered)
		} else if (sortField === 'Величина затрат') {
			sorted = sortUncTotalCost(sortField, sortOrder, uncsFiltered)
		}
		setUncsFiltered(sorted)
	}

	const updateHandleFiltered = (inputFiled: string, value: any) => {
		if (inputFiled === 'Расценка' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, Расценка: null }))
			} else {
				setFilterFields(prev => ({ ...prev, Расценка: value }))
			}
		}
		if (inputFiled === 'Техническое решение' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, 'Техническое решение': null }))
			} else {
				setFilterFields(prev => ({ ...prev, 'Техническое решение': value }))
			}
		}
		if (inputFiled === 'Кол-во' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, 'Кол-во': null }))
			} else {
				setFilterFields(prev => ({ ...prev, 'Кол-во': value }))
			}
		}
		if (inputFiled === 'Ед.изм.' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, 'Ед.изм.': null }))
			} else {
				setFilterFields(prev => ({ ...prev, 'Ед.изм.': value }))
			}
		}
		if (inputFiled === 'Параметры' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, Параметры: null }))
			} else {
				setFilterFields(prev => ({ ...prev, Параметры: value }))
			}
		}
		if (inputFiled === 'Напр., кВ' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, 'Напр., кВ': null }))
			} else {
				setFilterFields(prev => ({ ...prev, 'Напр., кВ': value }))
			}
		}
		if (inputFiled === 'Укр. норматив цены' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, 'Укр. норматив цены': null }))
			} else {
				setFilterFields(prev => ({ ...prev, 'Укр. норматив цены': value }))
			}
		}
		if (inputFiled === 'Региональный коэф.' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, 'Региональный коэф.': null }))
			} else {
				setFilterFields(prev => ({ ...prev, 'Региональный коэф.': value }))
			}
		}
		if (inputFiled === 'Величина затрат' && value !== undefined) {
			if (value === '') {
				setFilterFields(prev => ({ ...prev, 'Величина затрат': null }))
			} else {
				setFilterFields(prev => ({ ...prev, 'Величина затрат': value }))
			}
		}
	}

	const filtered = () => {
		let filtered: [] | IUnc[] = uncs.length ? uncs : []

		if (typeof filterFields['Расценка'] !== 'undefined') {
			const query = filterFields['Расценка']
			filtered = filteredUncCellCode(query, filtered)
		}
		if (typeof filterFields['Техническое решение'] !== 'undefined') {
			const query = filterFields['Техническое решение']
			filtered = filteredUncTechnicalSolutions(query, filtered)
		}
		if (typeof filterFields['Кол-во'] !== 'undefined') {
			const query = filterFields['Кол-во']
			filtered = filteredUncCount(query, filtered)
		}

		if (typeof filterFields['Ед.изм.'] !== 'undefined') {
			const query = filterFields['Ед.изм.']
			filtered = filteredUncUnit(query, filtered)
		}
		if (typeof filterFields['Параметры'] !== 'undefined') {
			const query = filterFields['Параметры']
			filtered = filteredUncParameters(query, filtered)
		}
		if (typeof filterFields['Напр., кВ'] !== 'undefined') {
			const query = filterFields['Напр., кВ']
			filtered = filteredUncVoltage(query, filtered)
		}
		if (typeof filterFields['Укр. норматив цены'] !== 'undefined') {
			const query = filterFields['Укр. норматив цены']
			filtered = filteredUncCost(query, filtered)
		}
		if (typeof filterFields['Региональный коэф.'] !== 'undefined') {
			const query = filterFields['Региональный коэф.']
			filtered = filteredUncRegionFactor(query, filtered)
		}
		if (typeof filterFields['Величина затрат'] !== 'undefined') {
			const query = filterFields['Величина затрат']
			filtered = filteredTotalUncCost(query, filtered)
		}

		setUncsFiltered(filtered)
	}

	const { setAnchorEl } = useContext(ModalContext)

	const openWindow = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			setAnchorEl!(e.currentTarget)
		},
		[setAnchorEl]
	)

	useEffect(() => {
		setUncsFiltered(uncs)
	}, [uncs])

	useEffect(() => {
		filtered()
	}, [filterFields])
	return (
		<>
			<div className={styles.container}>
				{sitePreparation ? (
					<div style={{ textAlign: 'end', marginBottom: 15 }}>
						<span data-popup={cellTags[1]} onClick={openWindow} className='link link-blue ml-8'>
							Подготовка территории
						</span>
					</div>
				) : null}
				<TableTabs uncs={uncs} uncCheckedTable={uncCheckedTable} />

				<div className={styles.table}>
					<Thead
						gridTemplateColumns={theadParams['gridTemplateColumns']}
						theadData={theadParams['theadData']}
						updateHandleSorting={updateHandleSorting}
						updateHandleChange={updateHandleFiltered}
					/>
					<div className={styles.container_body}>
						<div>{showLoader && <LinearProgress />}</div>
						<Tbody uncs={uncs} uncsFiltered={uncsFiltered} checkedTable={uncCheckedTable} />
					</div>
				</div>
			</div>
		</>
	)
}

export { TableContainer }
