export function formateDateTime(str) {
	if (!str) return null
	if (str && str.length === 10) {
		str = str * 1000
	}
	const date = new Date(str),
		day = date.getDate(),
		month = date.getMonth() + 1,
		year = date.getFullYear(),
		hours = date.getHours(),
		minutes = date.getMinutes()

	return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year} ${
		hours < 10 ? '0' + hours : hours
	}:${minutes < 10 ? '0' + minutes : minutes}`
}

export const formatDate = (str, send = false) => {
	if (!str) return null
	const date = send ? new Date(str.toString().split('.').reverse().join('-')) : new Date(str)

	let dd = date.getDate()
	if (dd < 10) dd = '0' + dd

	let mm = date.getMonth() + 1
	if (mm < 10) mm = '0' + mm

	let yy = date.getFullYear()
	if (yy < 10) yy = '0' + yy

	if (send) {
		return yy + '-' + mm + '-' + dd
	}

	return dd + '.' + mm + '.' + yy
}

export const sendDateWithotDay = (str, before = false) => {
	if (!str) return null
	let date = str.toString().split('.')
	date[0] = '01'
	date = date.reverse().join('-')
	date = new Date(date)
	const resultDate = new Date(date.setDate(before ? date.getDate() - 1 : date.getDate()))

	let dd = resultDate.getDate()
	if (dd < 10) dd = '0' + dd

	let mm = resultDate.getMonth() + 1
	if (mm < 10) mm = '0' + mm

	let yy = resultDate.getFullYear()
	if (yy < 10) yy = '0' + yy

	return yy + '-' + mm + '-' + dd
}

export const formatDateLong = (str, withoutDay) => {
	if (!str) return null
	const date = new Date(str)

	const year = date.getFullYear()
	let month = date.getMonth()

	if (withoutDay) {
		month = month + 1
		if (month?.toString().length === 1) {
			month = `0${month}`
		}
	}

	const months = [
		'январь',
		'февраль',
		'март',
		'апрель',
		'май',
		'июнь',
		'июль',
		'август',
		'сентябрь',
		'октябрь',
		'ноябрь',
		'декабрь',
	]

	if (withoutDay) {
		return `${month}.${year}`
	}

	return `${months[month]} ${year}`
}

export const timestampToDate = timestamp => {
	if (!timestamp) return '-'
	const date = new Date(timestamp * 1000)

	let day = date.getDate()
	if (day?.toString().length === 1) {
		day = `0${day}`
	}
	let month = date.getMonth() + 1
	if (month?.toString().length === 1) {
		month = `0${month}`
	}
	const year = date.getFullYear()

	return `${day}.${month}.${year}`
}

export const formatTime = str => {
	const time = new Date(str)
	let hours = time.getHours()
	if (hours < 10) hours = '0' + hours

	let mm = time.getMinutes()
	if (mm < 10) mm = '0' + mm

	return hours + ':' + mm
}

export function debounce(fn, ms) {
	let isCooldown = false

	return function () {
		if (isCooldown) return

		fn.apply(this, arguments)

		isCooldown = true

		setTimeout(() => (isCooldown = false), ms)
	}
}

export function searchByKey(arr, key, value) {
	let ind
	for (let i = 0; i < arr.length; i++) {
		if (!ind && value === arr[i][key]) ind = i
	}
	return ind
}

export function lightPretty(val) {
	const int = val.toString().replace(/[^\d.]/g, '')
	if (int.match(/\./)) {
		const arrStr = int.split('.')
		const beforeDot = arrStr[0]
			.toString()
			.replace(/[^\d.\b]/g, '')
			.replace(/\.{2}?$/, '.')
			.replace(/\.\,/g, '.')
			.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

		return `${beforeDot}.${arrStr[1]}`
	} else {
		return val
			.toString()
			.replace(/[^\d.\b]/g, '')
			.replace(/\.{2}?$/, '.')
			.replace(/\.\,/g, '.')
			.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
	}
}

export function prettyCost(val, precision = 2) {
	if (typeof val != 'object' && val === 0) return '0.00'
	if (!val) return '0.00'
	if (typeof val === 'string') val = val.replace(/,/g, '.')
	return val < 0 ? `-${lightPretty(Number(val).toFixed(precision))}` : lightPretty(Number(val).toFixed(precision))
}

export function intFormat(integer, flag = false, precision = 13) {
	if (!integer && flag) return '0.00'
	const negative = integer < 0 ? true : false
	let int = integer.toString().match(/\./)
		? integer.toString().replace(/[^\d.]/g, '')
		: integer.toString().replace(/[^\d,.]/g, '')

	if (int.match(/\./) || int.match(/\,/)) {
		const arrStr = int.match(/\./) ? int.split('.') : int.split(',')

		if (arrStr[0].length === 0 || (arrStr[0].length && +arrStr[0] === 0)) {
			arrStr[0] = '0'
		} else {
			arrStr[0] = arrStr[0].replace(/^0+/g, '')
		}

		const newArrStr = arrStr.slice(1)
		arrStr[1] = newArrStr.reduce((a, b) => a + b)
		int = arrStr[0] + '.' + arrStr[1]
		int = int.substr(0, int.indexOf('.') + precision)
	} else {
		const regWithSpaces = /(\d)(?=(\d{3})+([^\d.]|$))/g
		int = int.replace(regWithSpaces, '$1 ')
	}
	return negative ? -int : int
}
export function delSpaces(str) {
	return `${str}`.replace(/\s/g, '')
}

export function getMaxInArr(arr, keyName) {
	const sorted = arr.sort((a, b) => a[keyName] - b[keyName])
	const last = sorted[sorted.length - 1][keyName]
	const res = []

	for (let i = 0; i < last; i++) {
		res[i] = i + 1
	}

	return res
}

export const getNoun = (number, txt) => {
	const cases = [2, 0, 1, 1, 1, 2]
	return txt[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export const hasUndefinedKeys = obj => Object.values(obj).includes(undefined)

export const capitalizeFirstLetter = string => {
	return string ? string.charAt(0).toUpperCase() + string.slice(1) : null
}

export const getCurrentPositionUnc = (uncs, params) => {
	if (uncs && params.params.draggableId) {
		const tableNum = Number(params.params.destination.droppableId)
		const end = params.params.destination.index
		const start = params.params.source.index
		const id = params.params.draggableId

		const newArr = uncs.filter(item => {
			return item.tableNum === tableNum
		})
		const currentUnc = newArr.find(item => {
			return item.id === id
		})
		let position = newArr.indexOf(currentUnc) + 1
		if (start > end) {
			const diff = start - end
			position -= diff
		} else {
			const diff = end - start
			position += diff
		}
		console.log(position, 'positionfunc')
		return position
	}
}

export const currentServer = () => {
	// return 'prod'
	return window.location.host.indexOf('portal') >= 0 ? 'prod' : null
}

export const formatedDeflatorValue = (value, precision) => {
	value = Number(value).toFixed(precision)
	const splited = String(value)?.split('.')
	const whole = splited?.[0]
	const fraction = splited?.[1]

	const rln = value !== '' && value !== undefined && fraction !== undefined && fraction !== 0 ? fraction.length : 0

	if (precision >= rln) {
		const newVal = `${whole}.${splited?.[1] ? splited?.[1] : ''}${'0'.repeat(precision - rln)}`
		return newVal
	} else if (precision < rln) {
		const newVal = `${whole}.${splited?.[1] ? splited?.[1]?.slice(0, precision) : ''}`
		return newVal
	} else {
		return ''
	}
}
