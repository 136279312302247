import { AdminPanelType } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import {
	getUsersFetch,
	loadingUsers,
	setUser,
	setUsers,
	setCompanies,
	getCompanyGroupsFetch,
} from '@root-gipro/modules/AdminPanel/store/actions'
import {
	getUserAccess,
	getUserAuthRole,
	getUserRole,
	getUsersRoles,
} from '@root-gipro/modules/AdminPanel/store/helpers'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { fetchAuthHeaders, fetchData, fetchHeaders } from '@root-gipro/store/api'
import { authorizeApi } from '@root-gipro/store/api/index'
import { ICompany } from '@root-gipro/store/interfaces'
import { call, put, takeEvery } from 'redux-saga/effects'

function* getAuthToken() {
	try {
		const authorize: any = yield call(authorizeApi, 'auth')
		if (authorize && authorize.status === 'success') {
			yield localStorage.setItem('service_auth_token', authorize.access_token)

			yield put(getUsersFetch())
		}
	} catch (error) {
		console.log(error)
	}
	yield put(getUsersFetch())
}

function* getUsers() {
	try {
		yield put(loadingUsers(true))
		let users: any = []

		yield fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users`, {
			method: 'GET',
			headers: fetchAuthHeaders(),
		})
			.then(res => res.json())
			.then(data => data.users)
			.then(data => {
				users = data
			})

		const usersRoles = yield getUsersRoles()

		const createUserObject = async () => {
			const newUsers = await Promise.all(
				users.map(async (user: IUser) => {
					let newUse
					const userAccess = await getUserAccess(user.id)

					usersRoles.forEach((role: any) => {
						if (user.id === role.userId) {
							newUse = { ...user, roleId: role.roleId, access: userAccess[0] }
						}
					})
					return newUse
				})
			)
			return newUsers
		}

		const newUsers = yield createUserObject()

		yield put(setUsers(newUsers))
		yield put(loadingUsers(false))
	} catch (error) {
		console.log(error)
		yield put(loadingUsers(false))
	}
}

function* setUserRoleFetch({
	userId,
	role,
}: {
	type: typeof AdminPanelType.SET_USER_ROLE_FETCH;
	userId: number;
	role: number;
}) {
	try {
		const userRole: any = yield getUserRole(userId)
		const userRoleAuth = yield getUserAuthRole(userId)

		const switchRole = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/userRoles/${userRole}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify({ roleId: role }),
			})
			const data = await req.json()
			return data
		}
		const switchRoleAuth = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/userRoles/${userRoleAuth}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify({ roleId: role }),
			})
			const data = await req.json()
			return data
		}

		yield call(switchRole)
		yield call(switchRoleAuth)
		yield put(getUsersFetch())
	} catch (error) {
		console.log(error)
	}
}

function* createUser({ user }: { type: typeof AdminPanelType.CREATE_USER; user: IUser }) {
	try {
		const reqApi = async () => {
			const res = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users`, {
				method: 'POST',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(user),
			})
			if (res.status === 200 && res.ok) {
				const data = await res.json()
				return data
			} else {
				const data = await res.json()
				const errorMessage = data.errors.EXCESS_QUANTITY.EXCESS_QUANTITY
				if (data.errors.EXCESS_QUANTITY && data.errors.EXCESS_QUANTITY.EXCESS_QUANTITY) {
					throw Error(errorMessage)
				} else {
					throw Error(data.errors)
				}
			}
		}
		yield call(reqApi)
		yield put(getUsersFetch())
	} catch (error) {
		console.log(error)

		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

function* checkUserInfoFetch({ id }: { type: typeof AdminPanelType.CHECK_USER_INFO; id: number }) {
	try {
		const getUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'GET',
				headers: fetchAuthHeaders(),
			})
			const data = await req.json()
			return data.user
		}

		const user = yield call(getUser)
		yield put(setUser(user))
	} catch (error) {
		console.log(error)
	}
}

function* updateUserInfo({
	id,
	user,
	date_start,
	date_end,
	access,
	idUserAccess,
}: {
	type: typeof AdminPanelType.UPDATE_USER_INFO_FETCH;
	id: number;
	user: IUser;
	date_start: number;
	date_end: number;
	access: boolean;
	idUserAccess: number | null;
}) {
	const { access: acessObj, ...clearUser } = user
	try {
		const updateUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(clearUser),
			})
			const data = await req.json()
			return data.user
		}
		const updateAccess = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/userAccess/${idUserAccess}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify({ date_start, date_end, access }),
			})
			const data = await req.json()
			return data.user
		}
		yield call(updateUser)
		if (typeof idUserAccess === 'number') {
			yield call(updateAccess)
		}
		yield put(getUsersFetch())
	} catch (error) {
		console.log(error)
	}
}

function* deleteUser({ id }: { type: typeof AdminPanelType.DELETE_USER; id: number }) {
	try {
		const deleteUserFetch = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'DELETE',
				headers: fetchAuthHeaders(),
			})
			const data = await req.json()
			return data.user
		}

		yield call(deleteUserFetch)
		yield put(getUsersFetch())
	} catch (error) {
		console.log(error)
	}
}

function* fetchCompanyGroupsInfo() {
	try {
		const company: ICompany = yield call(fetchData, '/company', (res: any) => res.company)
		yield put(setCompanies(company))
	} catch (error) {
		console.log(error)
	}
}

function* createCompany({ company }: { type: typeof AdminPanelType.CREATE_COMPANY; company: any }) {
	try {
		const reqApi = async () => {
			const { id, ...compObj } = company

			const comp = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/company`, {
				method: 'POST',
				headers: fetchHeaders(),
				body: JSON.stringify(compObj),
			})

			const dataComp = await comp.json()
			return dataComp
		}
		yield call(reqApi)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

function* updateCompanyInfo({
	company,
}: {
	type: typeof AdminPanelType.UPDATE_COMPANY;
	id: number | string;
	company: any;
}) {
	try {
		const { id, ...compObj } = company
		const updateCompany = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/company/${id}`, {
				method: 'PATCH',
				headers: fetchHeaders(),
				body: JSON.stringify(compObj),
			})
			const data = await req.json()
			return data
		}
		yield call(updateCompany)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

export default function* adminPanel() {
	yield takeEvery(AdminPanelType.GET_AUTH_USER_TOKEN, getAuthToken)
	yield takeEvery(AdminPanelType.GET_USERS_FETCH, getUsers)
	yield takeEvery(AdminPanelType.CHECK_USER_INFO, checkUserInfoFetch)
	yield takeEvery(AdminPanelType.UPDATE_USER_INFO_FETCH, updateUserInfo)
	yield takeEvery(AdminPanelType.DELETE_USER, deleteUser)
	// yield takeEvery(AdminPanelType.GET_USER_ROLE_FETCH, getUserRole)
	yield takeEvery(AdminPanelType.CREATE_USER, createUser)
	yield takeEvery(AdminPanelType.SET_USER_ROLE_FETCH, setUserRoleFetch)
	yield takeEvery(AdminPanelType.UPDATE_COMPANY, updateCompanyInfo)
	yield takeEvery(AdminPanelType.CREATE_COMPANY, createCompany)
	yield takeEvery(AdminPanelType.GET_COMPANY_FETCH, fetchCompanyGroupsInfo)
}
