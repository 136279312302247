import cn from 'classnames'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { ModalContext } from '@root-gipro/context/modal-context'
import { WrapperCell } from '@root-gipro/modules/userProjects/components/Project/UncTable/WrapperCell'
import { Cell } from '@root-gipro/modules/userProjects/components/Project/UncTable/Сell'
import Voltage from '@root-gipro/modules/userProjects/components/Project/Voltage'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'
import { gridTemplateColumns } from '@root-gipro/modules/userProjects/models/consts/unc-thead.const'
import { CellsProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import {
	changeCounter,
	deleteUnc,
	editPositionUnc,
	setActiveTotal,
	setActiveUncs,
} from '@root-gipro/modules/userProjects/store/actions'
import { addActiveUnc } from '@root-gipro/modules/userProjects/utils/helpers'
import { removeSpaceFromNumber } from '@root-gipro/modules/userProjects/utils/removeSpaceFromNumber'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import { IRegion, IUnc } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import { intFormat, prettyCost } from '@root-gipro/utils/helpers/common'
import RepeatIcon from '@material-ui/icons/Repeat'
import CloseIcon from '@material-ui/icons/Close'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import UncReplaceModal from '@root-gipro/modules/userProjects/components/Project/UncTable/UncReplaceModal'
import { useLocation } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'

export const Cells: React.FC<CellsProps> = ({
	uncs,
	voltageRef,
	unc,
	index,
	currentCellTags,
	isArchived,
	currentUncSort,
	checkedTable,
}) => {
	const [uncCount, setUncCount] = useState<number>(unc.count)
	const { activeUncs, loading, projectObj } = useSelector((state: IState) => state.userProjects)
	const { regions } = useSelector((state: IState) => state.public)
	const [isActualUncSort, setIsActualUncSort] = useState<boolean>(true)
	const { setAnchorEl, accessoryRef } = useContext(ModalContext)
	const location = useLocation()
	const [openTooltip, setOpenTooltip] = React.useState(false)
	const isCommonProjects = location.pathname.includes('common-projects')

	const handleClose = () => {
		setOpenTooltip(false)
	}

	const handleOpen = () => {
		setOpenTooltip(true)
	}

	const [anchorElUncSort, setAnchorElUncSort] = React.useState<null | HTMLElement>(null)

	const handleClickUncSort = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUncSort(event.currentTarget)
	}

	const dispatch = useDispatch()
	const rowRef = useRef<HTMLInputElement>(null)

	const trClickHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>, unc: IUnc) => {
			if (e.ctrlKey) {
				const { clone } = addActiveUnc(activeUncs, unc)
				dispatch(setActiveUncs(clone))
			} else {
				if (activeUncs.length === 1 && activeUncs[0].id === unc.id) {
					return
				} else {
					dispatch(setActiveUncs([unc]))
				}
			}
		},
		[activeUncs, setActiveUncs]
	)
	const regionTdClicHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			if (!isArchived) {
				e.stopPropagation()
				if (e.ctrlKey) {
					const { clone } = addActiveUnc(activeUncs, unc)
					dispatch(setActiveUncs(clone))
				} else {
					if (!activeUncs.some(elem => elem.id === unc.id)) {
						dispatch(setActiveUncs([unc]))
					}
				}
				const { index } = addActiveUnc(activeUncs, unc)
				if (accessoryRef) accessoryRef.current = String(index)
				setAnchorEl!(e.currentTarget)
				if (rowRef.current) rowRef.current.focus()
			}
		},
		[activeUncs, unc]
	)

	const commentTdClickHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation()
			if (e.ctrlKey) {
				const { clone } = addActiveUnc(activeUncs, unc)
				dispatch(setActiveUncs(clone))
			} else {
				if (!activeUncs.some(elem => elem.id === unc.id)) {
					dispatch(setActiveUncs([unc]))
				}
				const { index } = addActiveUnc(activeUncs, unc)
				if (accessoryRef) accessoryRef.current = String(index)
				setAnchorEl!(e.currentTarget)
				if (rowRef.current) rowRef.current.focus()
			}
		},
		[activeUncs, unc]
	)

	const region: IRegion | undefined = useMemo(() => {
		return regions?.find(elem => +elem.id === +unc.regionId)
	}, [unc, regions, unc.regionId])

	useEffect(() => {
		setIsActualUncSort(
			isCommonProjects || Number(currentUncSort) == 2018 || Number(currentUncSort) === Number(unc.unc_sort)
		)

		setUncCount(unc.count)
	}, [unc])

	const checkDis = (value: number) => {
		if (value === 8) {
			return true
		} else if (value <= 5) {
			return false
		}
		return true
	}

	return (
		<Draggable key={unc.id} index={index} draggableId={unc.id.toString()} isDragDisabled={checkDis(checkedTable)}>
			{(provided, snapshot) => (
				<Tooltip
					open={openTooltip && !isActualUncSort}
					onClose={handleClose}
					onOpen={handleOpen}
					title={<Typography>Расценка исключена из справочника УНЦ 2024, подлежит замене</Typography>}
				>
					<div tabIndex={+unc.id} ref={rowRef} className={cn(styles.rowWrapper)}>
						{!checkDis(checkedTable) ? (
							<div
								className={cn(
									styles.buttonGroupLeft,
									{ [styles.onlyUp]: index === uncs.length - 1 },
									{ [styles.onlyDown]: index === 0 }
								)}
							>
								<IconButton
									className={cn(styles.iconWrap, styles.swapUpBtn)}
									disabled={loading}
									onClick={e => {
										e.stopPropagation()
										const params = {
											draggableId: unc.id.toString(),
											destination: { index: index - 1, droppableId: `tbody ${checkedTable}` },
											source: { index: 0, droppableId: `tbody ${checkedTable}` },
										}
										dispatch(editPositionUnc(params))
									}}
								>
									<ArrowUpwardIcon className={cn(styles.icon, styles.swapUpIcon)} />
								</IconButton>
								<IconButton
									className={cn(styles.iconWrap, styles.swapDownBtn)}
									disabled={loading}
									onClick={e => {
										e.stopPropagation()
										const params = {
											draggableId: unc.id.toString(),
											destination: { index: index + 1, droppableId: `tbody ${checkedTable}` },
											source: { index: 0, droppableId: `tbody ${checkedTable}` },
										}
										dispatch(editPositionUnc(params))
									}}
								>
									<ArrowDownwardIcon className={cn(styles.icon)} />
								</IconButton>
							</div>
						) : null}
						<div
							onClick={e => trClickHandler(e, unc)}
							key={unc.id + 1}
							className={
								snapshot.draggingOver?.includes('tableTabs')
									? cn(styles.draggingOver)
									: cn(styles.rowItem, {
											[styles.activeRow]: activeUncs.some(active => active.id === unc.id),
											[styles.rowWrapperNonRelevant]: !isActualUncSort,
									  })
							}
							{...provided.draggableProps}
						>
							<div
								className={cn(styles.grid, styles.alignTheElement)}
								style={{
									gridTemplateColumns: gridTemplateColumns,
									gap: '8px',
									padding: '8px',
								}}
							>
								<WrapperCell currentCellTags={currentCellTags}>
									<Cell cellTags={[cellTags[0], cellTags[1]]}>
										<div
											{...provided.dragHandleProps}
											ref={provided.innerRef}
											className={cn(styles.tableCell, styles.textAlignCenter)}
										>
											{index + 1}
										</div>
									</Cell>

									<Cell cellTags={[cellTags[0], cellTags[1]]}>
										<div {...provided.dragHandleProps} className={cn(styles.tableCell)}>
											{unc?.uncCell?.code}
										</div>
									</Cell>

									<Cell cellTags={[cellTags[0], cellTags[1]]}>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<Tooltip
												key={unc.uncMain.name}
												title={unc.uncMain.name}
												enterDelay={500}
												enterNextDelay={500}
												placement='top'
											>
												<div {...provided.dragHandleProps} className={cn(styles.tableCell)}>
													{unc.uncMain.name}
												</div>
											</Tooltip>
											<div
												data-popup='comment'
												onClick={commentTdClickHandler}
												style={{ cursor: 'pointer' }}
												className={cn(styles.tableCell)}
											>
												<span className='comment-icon'></span>
											</div>
										</div>
									</Cell>
									<Cell cellTags={[cellTags[0], cellTags[1]]}>
										<div className={cn(styles.tableCell)}>
											<input
												onChange={e => {
													setUncCount(intFormat(e.currentTarget.value))
												}}
												disabled={isArchived}
												onBlur={e => {
													dispatch(changeCounter(unc.id, removeSpaceFromNumber(e.currentTarget.value)))
												}}
												style={{ borderColor: uncCount ? '#e0e0e0' : '#d33131' }}
												className={styles.uncCounter}
												value={uncCount ? intFormat(uncCount) : 0}
											/>
										</div>
									</Cell>
									<Cell cellTags={[cellTags[0], cellTags[1]]}>
										<div className={cn(styles.tableCell, styles.textAlignCenter)}>{unc.unit ?? unc.uncMain.unit} </div>
									</Cell>
									<Cell cellTags={[cellTags[0], cellTags[1]]}>
										<Tooltip
											key={unc.uncCell?.parameter}
											title={unc.uncCell?.parameter}
											enterDelay={500}
											enterNextDelay={500}
											placement='top'
										>
											<div className={cn(styles.tableCell)}>{unc.uncCell?.parameter}</div>
										</Tooltip>
									</Cell>
									<Cell cellTags={[cellTags[0], cellTags[1]]}>
										<div className={cn(styles.tableCell, styles.accent, styles.textAlignCenter)}>
											<Voltage voltageRef={voltageRef} dataAttr='volt-unc-table' unc={unc} isArchived={isArchived} />
										</div>
									</Cell>

									<Cell cellTags={[cellTags[0]]}>
										<div className={cn(styles.tableCell)}>
											{prettyCost(unc.cost / 1000)}
											{unc.uncMain.code === 'С1' ? (
												<span className='currency'> м²</span>
											) : (
												<span className='currency'> тыс&nbsp;₽</span>
											)}
										</div>
									</Cell>
									<Cell cellTags={[cellTags[0]]}>
										<div
											// data-popup={unc.uncMain.code !== 'Б1' ? 'region' : null}
											// style={{ cursor: unc.uncMain.code !== 'Б1' ? 'pointer' : 'default' }}
											data-popup={unc.regionFactor?.value && unc.uncMain.code !== 'Б1' ? 'region' : null}
											style={{ cursor: unc.regionFactor?.value && unc.uncMain.code !== 'Б1' ? 'pointer' : 'default' }}
											data-region={unc.regionId}
											onClick={regionTdClicHandler}
											className={cn(styles.tableCell, styles.regionName)}
										>
											<span>{unc.regionFactor?.value ? unc.regionFactor.value : '1.00'}</span>
											<span className={cn(styles.accent, 'ml-4')}>{region?.name}</span>
										</div>
									</Cell>
									<Cell cellTags={[cellTags[0]]}>
										<div className={cn(styles.totalCost, styles.tableCell)}>
											{prettyCost(unc.totalCost / 1000)}
											{unc.uncMain.code === 'С1' ? (
												<span className='currency'> м²</span>
											) : (
												<span className='currency'> тыс&nbsp;₽</span>
											)}
										</div>
									</Cell>
									<Cell cellTags={[cellTags[1]]}>
										<div className={cn(styles.tableCell)}>
											{' '}
											{prettyCost(unc.cost / 1000)}
											{unc.uncMain.code === 'С1' ? (
												<span className='currency'> м²</span>
											) : (
												<span className='currency'> тыс&nbsp;₽</span>
											)}
										</div>
									</Cell>
									<Cell cellTags={[cellTags[1]]}>
										<div
											data-popup={unc.regionFactor?.value && unc.uncMain.code !== 'Б1' ? 'region' : null}
											style={{ cursor: unc.regionFactor?.value && unc.uncMain.code !== 'Б1' ? 'pointer' : 'default' }}
											data-region={unc.regionId}
											onClick={regionTdClicHandler}
											className={cn(styles.tableCell, styles.regionName)}
										>
											<span>{unc.regionFactor?.value ? unc.regionFactor.value : '1.00'}</span>
											<span className={cn(styles.accent, 'ml-4')}>{region?.name}</span>
										</div>
									</Cell>
									<Cell cellTags={[cellTags[1]]}>
										<div className={cn(styles.tableCell)}>
											{prettyCost(unc.totalCost / 1000)}
											{unc.uncMain.code === 'С1' ? (
												<span className='currency'> м²</span>
											) : (
												<span className='currency'> тыс&nbsp;₽</span>
											)}
										</div>
									</Cell>
								</WrapperCell>
							</div>
						</div>
						{!isArchived ? (
							<div className={cn(styles.buttonGroup, { [styles.isNoActualUncSort]: isActualUncSort })}>
								<IconButton
									className={styles.iconWrap}
									disabled={loading}
									onClick={e => {
										e.stopPropagation()
										dispatch(deleteUnc(unc.id))
										if (activeUncs[0]?.id === unc.id) {
											dispatch(setActiveTotal(null))
										}
									}}
								>
									<CloseIcon className={cn(styles.icon, styles.delBtn)} />
								</IconButton>
								<IconButton className={cn(styles.iconWrap, styles.iconUncReplace)} onClick={handleClickUncSort}>
									<RepeatIcon className={cn(styles.icon)} />
								</IconButton>
							</div>
						) : null}
						{anchorElUncSort && (
							<UncReplaceModal
								anchorEl={anchorElUncSort}
								uncCellId={unc.uncCellId}
								uncId={unc.id}
								userProjectId={projectObj.id}
								handleClose={() => setAnchorElUncSort(null)}
							/>
						)}
					</div>
				</Tooltip>
			)}
		</Draggable>
	)
}
