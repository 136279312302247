import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { ICompany, IOpenModalCreateCompany } from '@root-gipro/store/interfaces'

export enum AdminPanelType {
	GET_AUTH_USER_TOKEN = 'GET_AUTH_USER_TOKEN',
	GET_USERS_FETCH = 'GET_USERS_FETCH',
	GET_USER_ROLE_FETCH = 'GET_USER_ROLE_FETCH',
	SET_USER_ROLE_FETCH = 'SET_USER_ROLE_FETCH',
	SET_USERS = 'SET_USERS',
	SET_USER = 'SET_USER',
	CREATE_USER = 'CREATE_USER',
	CHECK_USER_INFO = 'CHECK_USER_INFO',
	UPDATE_USER_INFO_FETCH = 'UPDATE_USER_INFO_FETCH',
	DELETE_USER = 'DELETE_USER',
	LOADING_USERS = 'LOADING_USERS',
	LOADING_SETTINGS = 'LOADING_SETTINGS',
	UPDATE_COMPANY = 'UPDATE_COMPANY',
	CREATE_COMPANY = 'CREATE_COMPANY',
	GET_COMPANY_FETCH = 'GET_COMPANY_FETCH',
	SET_COMPANY = 'SET_COMPANY',
	SET_OPEN_CREATE_COMPANY = 'SET_OPEN_CREATE_COMPANY',
	SET_COMPANY_STATE = 'SET_COMPANY_STATE',
}

interface LoadingUsers {
	type: AdminPanelType.LOADING_USERS;
	loading: boolean;
}
interface SettingsUsers {
	type: AdminPanelType.LOADING_SETTINGS;
	loading: boolean;
}

interface GetAuthUserToken {
	type: AdminPanelType.GET_AUTH_USER_TOKEN;
}
interface GetUsersFetch {
	type: AdminPanelType.GET_USERS_FETCH;
}

interface SetUserRoleFetch {
	type: AdminPanelType.SET_USER_ROLE_FETCH;
	userId: number;
	role: number;
}

interface GetUserRoleFetch {
	type: AdminPanelType.GET_USER_ROLE_FETCH;
	id: number;
}
interface CheckUserInfo {
	type: AdminPanelType.CHECK_USER_INFO;
	id: number;
}
interface UpdateUserInfo {
	type: AdminPanelType.UPDATE_USER_INFO_FETCH;
	id: number;
	user: IUser;
	date_start: number;
	date_end: number;
	access: number;
	idUserAccess: number | null;
}

interface CreateUsers {
	type: AdminPanelType.CREATE_USER;
	user: IUser;
	role: number;
}
interface SetUsers {
	type: AdminPanelType.SET_USERS;
	users: IUser[];
}
interface SetUser {
	type: AdminPanelType.SET_USER;
	user: unknown;
}

interface DeleteUser {
	type: AdminPanelType.DELETE_USER;
	id: number;
}

interface updateCompanyInfo {
	type: AdminPanelType.UPDATE_COMPANY;
	company: any;
}

interface createCompany {
	type: AdminPanelType.CREATE_COMPANY;
	company: any;
}

interface getCompanyFetch {
	type: AdminPanelType.GET_COMPANY_FETCH;
}

interface setCompanies {
	type: AdminPanelType.SET_COMPANY;
	companies: any[];
}

interface setOpenCreateCompany {
	type: AdminPanelType.SET_OPEN_CREATE_COMPANY;
	modal: IOpenModalCreateCompany;
}

interface setCompanyState {
	type: AdminPanelType.SET_COMPANY_STATE;
	company: ICompany | null;
}

export type AdminPanelTypes =
	| GetAuthUserToken
	| GetUsersFetch
	| GetUserRoleFetch
	| SetUserRoleFetch
	| SetUsers
	| SetUser
	| LoadingUsers
	| SettingsUsers
	| CheckUserInfo
	| UpdateUserInfo
	| CreateUsers
	| DeleteUser
	| updateCompanyInfo
	| createCompany
	| getCompanyFetch
	| setCompanies
	| setOpenCreateCompany
	| setCompanyState
