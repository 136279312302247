import React, { useEffect, useState } from 'react'

import styles from '@root-gipro/modules/AdminPanel/styles/adminPanel.module.scss'

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core'
import { range } from 'lodash'
import DeflatorInput from './components/DeflatorInput'
import { useDispatch, useSelector } from 'react-redux'

import { updateDeflatorsValue } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces'
import Loader from '@root-gipro/shared/Loader/Loader'
import { formatedDeflatorValue } from '@root-gipro/utils/helpers/common'

interface deflatorValue {
	[index: string]: any
}

const years = range(2018, 2029)

const AdminSettings = () => {
	const { deflators } = useSelector((state: IState) => state.userProjects)
	const { settingsLoading } = useSelector((state: IState) => state.adminPanel)
	const dispatch = useDispatch()
	const [precision, setPrecision] = useState<number>(0)

	const [newDeflatorsValue, setNewDeflatorsValue] = useState<any | []>([])
	const [isInit, setisInit] = useState(false)

	useEffect(() => {
		setPrecision(deflators?.precision ? deflators?.precision : 0)
	}, [deflators])

	useEffect(() => {
		if (deflators?.values && !isInit) {
			const formatedValues = deflators.values.map((deflator: any) => {
				return {
					...deflator,
					value: formatedDeflatorValue(deflator.value, precision),
				}
				// }
			})
			setNewDeflatorsValue(formatedValues)
			newDeflatorsValue.length > 0 ? setisInit(true) : setisInit(false)
		} else if (newDeflatorsValue.length > 0) {
			setNewDeflatorsValue((prev: any) =>
				prev.map((deflator: any) => {
					return {
						...deflator,
						value: formatedDeflatorValue(deflator.value, precision),
					}
				})
			)
		}
	}, [deflators, precision])

	const upDeflatorsValue = () => {
		dispatch(updateDeflatorsValue({ precision, deflators: newDeflatorsValue }))
	}

	const onChangeValue = ({ year, value }: { year: number; value: string }) => {
		const index = newDeflatorsValue!.findIndex((deflator: deflatorValue) => deflator?.year === year)
		if (index === -1) {
			setNewDeflatorsValue((prev: any) => [...prev, { year, value }])
		} else {
			setNewDeflatorsValue((prev: any) => {
				const filtredVal = prev.filter((item: deflatorValue, i: any) => i !== index)
				return [...filtredVal, { year, value }]
			})
		}
	}
	return (
		<div className='adminPanel-container'>
			<Tabs
				value={0}
				className={styles.tabs}
				aria-label='deflators'
				TabIndicatorProps={{ style: { background: 'transparent' } }}
			>
				<Tab className={styles.tab} label='Индексы-дефляторы' />
			</Tabs>
			{settingsLoading ? (
				<Loader />
			) : (
				<Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
					<div style={{ display: 'flex', alignItems: 'center', gap: 8, maxWidth: 500 }}>
						<label style={{ display: 'block', whiteSpace: 'nowrap' }}>Точность расчета</label>
						<TextField
							id='outlined-password-input'
							type='number'
							autoComplete='current-password'
							variant='outlined'
							value={precision}
							InputProps={{ inputProps: { min: 1, max: 20 } }}
							onChange={e => setPrecision(+e.target.value)}
						/>
					</div>
					{years.map((year, i) => (
						<DeflatorInput
							key={year}
							year={year}
							precision={precision}
							initValue={
								newDeflatorsValue.find((e: any) => {
									return e?.year == year
								})?.value
							}
							value={''}
							onChangeValue={onChangeValue}
						/>
					))}
					<Button
						style={{ maxWidth: 500, color: '#fff' }}
						variant='contained'
						color='primary'
						onClick={upDeflatorsValue}
					>
						Сохранить
					</Button>
				</Box>
			)}
		</div>
	)
}

export default AdminSettings
